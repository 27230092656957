import React from 'react';
import { Person } from '~/components/interview/data';
import { useIsJapanese } from '~/hooks';
import styled from 'styled-components';
import { Link } from 'gatsby-plugin-react-i18next';
import { media } from '~/styles';

export const InterviewLinkCard: React.VFC<{ data: Person; id: string }> = ({
  data,
  id,
}) => {
  const isJapanese = useIsJapanese();
  const info = isJapanese ? data.ja : data.en;
  return (
    <Card>
      <CardInner to={`/interview/${id}`}>
        <Figure>
          <img src={info.img} alt="" />
        </Figure>
        <Text>
          <Lead>{info.lead}</Lead>
          <Title>{info.title}</Title>
          <Name>{info.name}</Name>
        </Text>
      </CardInner>
    </Card>
  );
};

const Card = styled.li`
  width: 100%;
  max-width: 396px;
  padding: 16px;
  border: 1px solid #484848;
  transition-duration: 0.3s;
  &:hover {
    border-color: #ffffff;
  }
`;
const CardInner = styled(Link)`
  display: flex;
  gap: 16px;
  align-items: self-start;
`;
const Figure = styled.figure`
  width: 120px;
  aspect-ratio: 3 / 2;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
`;
const Lead = styled.span`
  padding-bottom: 12px;
  border-bottom: 1px solid #484848;
  margin-bottom: 12px;
  ${media.mdUp} {
    font-size: 14px;
  }
  ${media.mdDown} {
    font-size: 13px;
  }
`;
const Title = styled.span`
  ${media.mdUp} {
    font-size: 12px;
  }
  ${media.mdDown} {
    font-size: 11px;
  }
`;
const Name = styled.span`
  ${media.mdUp} {
    font-size: 14px;
  }
  ${media.mdDown} {
    font-size: 13px;
  }
`;
