import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useIntersection as useIntersectionObserver } from 'react-use';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const useIntersection = <T extends HTMLElement>(): [
  boolean,
  React.RefObject<T>,
] => {
  const intersectionRef = useRef<T>(null);
  const [isIntersection, setIsIntersection] = useState(false);
  const intersection = useIntersectionObserver(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  useMemo(() => {
    if (intersection && intersection.isIntersecting) {
      setIsIntersection(true);
    }
  }, [intersection]);

  return [isIntersection, intersectionRef];
};

export const useSmoothScroll = (): [React.RefObject<HTMLElement>] => {
  const scrollingContainerRef = useRef<HTMLElement>(null);

  const data = {
    ease: 0.1,
    current: 0,
    previous: 0,
    rounded: 0,
  };

  const smoothScrollingHandler = () => {
    data.current = window.scrollY;
    data.previous += (data.current - data.previous) * data.ease;
    data.rounded = Math.round(data.previous * 100) / 100;

    if (scrollingContainerRef && scrollingContainerRef.current) {
      scrollingContainerRef.current.style.transform = `translateY(-${data.previous}px)`;
    }

    requestAnimationFrame(() => smoothScrollingHandler());
  };

  useEffect(() => {
    requestAnimationFrame(() => smoothScrollingHandler());
  }, []);

  return [scrollingContainerRef];
};

export const useIsJapanese = (): boolean => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  return lang === 'ja';
};
