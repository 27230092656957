import nakaharaImg from '~/assets/images/interview/nakahara_main.jpg';
import hsuImg from '~/assets/images/interview/hsu_main.jpg';

type Data = {
  nakahara: Person;
  hsu: Person;
};
export type Person = {
  ja: Info;
  en: Info;
};

type Info = {
  lead: string;
  name: string;
  title: string;
  img: string;
};

export const InterviewData: Data = {
  nakahara: {
    ja: {
      name: '中原さとみ',
      title: 'シネマティック・スーパーバイザー',
      lead: 'ハリウッド作品を手がけたシネマティックアーティストがグローバルな作品を成功に導くための秘訣',
      img: nakaharaImg,
    },
    en: {
      name: 'Satomi Nakahara',
      title: 'Cinematic Supervisor',
      lead: 'Cinematic Artist Who Has Worked on Hollywood Productions Share Her Secrets for Successful Global Productions',
      img: nakaharaImg,
    },
  },
  hsu: {
    ja: {
      name: 'レイ・シュー',
      title: 'アニメーション・スーパーバイザー',
      lead: 'ベテランアニメーターがBlizzardで得たものとは',
      img: hsuImg,
    },
    en: {
      name: 'Ray Hsu',
      title: 'Animation Supervisor',
      lead: 'What Did This Veteran Animator Gain at Blizzard?',
      img: hsuImg,
    },
  },
};
