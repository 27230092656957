import { InterviewData } from '~/components/interview/data';
import { InterviewLinkCard } from '~/components/interview/molecules/InterviewLinkCard';
import React from 'react';
import styled from 'styled-components';
import { media } from '~/styles';

export const InterviewLinks: React.VFC = (props) => {
  return (
    <Wrapper {...props}>
      {Object.entries(InterviewData).map(([key, value]) => (
        <InterviewLinkCard key={key} id={key} data={value} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-inline: auto;
  ${media.mdUp} {
    width: 816px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
  ${media.mdDown} {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;
