import React from 'react';
import { Link } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { colors, media, easings } from '~/styles';

interface RoundedButtonProps {
  text: string;
  to: string;
  state?: any;
}

const RoundedButton: React.VFC<RoundedButtonProps> = ({ text, to, state }) => (
  <Wrapper>
    <Button to={to} state={state ?? {}}>
      {text}
    </Button>
  </Wrapper>
);

const Wrapper = styled.div`
  text-align: center;
`;

const Button = styled(Link)`
  display: inline-block;

  line-height: 1.25;
  color: ${colors.black};
  border-radius: 999px;
  background-color: ${colors.white};
  cursor: pointer;
  transition: transform 0.6s ${easings.easeOutCubic};

  ${media.smUp} {
    font-size: 16px;
    padding: 18px 38px;
  }

  ${media.smDown} {
    font-size: 14px;
    padding: 14px 30px;
  }

  &:hover {
    transition: transform 0.3s ${easings.easeOutCubic};
    transform: scale(0.96);
  }
`;

export default RoundedButton;
